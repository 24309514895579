import React from 'react'
import Helmet from 'react-helmet'
import Layout from '@components/layout'
import OrderPayment from '@components/order/status/order-payment'

const Status = () => (
  <Layout>
    <Helmet title="Order Payment - Rooms To Go" />
    <OrderPayment />
  </Layout>
)

export default Status
