import React from 'react'
import { node } from 'prop-types'
import { sentryLogger, levels, setExtra } from '@helpers/sentry-logger'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    })
    // You can also log error messages to an error reporting service here
    sentryLogger({
      configureScope: {
        type: setExtra,
        error,
        level: levels.error,
      },
      captureMessage: {
        type: 'text',
        message: `React render error: ${errorInfo}`,
        level: levels.error,
      },
    })
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state
    if (errorInfo) {
      console.error(`Error: ${error}: ${errorInfo}`)
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: node,
}

export default ErrorBoundary
