import { fetchAddressLookup } from '@services/checkout'
import { getAddressString, titleCase, validatePoBox, validateZip } from '@helpers/string-helper'

/**
 * Checks a given address object for field validation and optionally gives suggestions for
 * the closest matching valid address
 *
 * @param {object} address - An address object containing keys 'address1', '<address2>', 'city', 'state', 'zip'
 * @param {boolean} billing - Is this address a billing address?
 * @return {array} An array of invalid address fields
 */
export const checkManualAddress = (address, billing = false) => {
  let invalidFields = []

  // check for empty fields
  const entries = Object.entries(address)
  for (let i = 0; i < entries.length; i++) {
    // creates 2-dimensional array of key-value pairs
    const [entryKey, entryData] = entries[i]
    if (entryData === '' && entryKey !== 'address2') {
      invalidFields.push(entryKey)
    } else if (entryKey === 'zip' && !validateZip(entryData)) {
      invalidFields.push('zip')
    }
  }

  // if it's billing, validate PO Box
  if (!billing) {
    if (!validatePoBox(address.address1) || !validatePoBox(address.address2)) {
      invalidFields = ['street', 'po box', ...invalidFields]
    }
  }

  return invalidFields
}

/**
 * Tries to find an address suggestion
 *
 * @param {object} address - An address object containing keys 'address1', '<address2>', 'city', 'state', 'zip'
 * @param {boolean} badPlus4 - Does this zip contain a 4-digit extension?
 * @return {object || null} An object with address suggestion or null
 */
export const getAddressSuggestion = async ({ addressState: address, badPlus4 = false }) => {
  const addressString = getAddressString(address)
  const addressLookup = await fetchAddressLookup(addressString)
  if (addressLookup?.results?.[0]?.suggestion) {
    const suggestion = addressLookup.results[0].suggestion.toLowerCase()
    const [suggestedAddress1, location] = suggestion.split(',')
    const [suggestedZip, suggestedState] = location.split(' ').reverse()
    const suggestedCity = location
      .split(' ')
      .reverse()
      .slice(2)
      .reverse()
      .join(' ')
      .trim()
    if (
      // Address suggestion service returns apt as part of address1, so we concat them in this comparison
      suggestedAddress1 !== `${address.address1.toLowerCase()} ${address.address2.toLowerCase()}` ||
      suggestedCity !== address.city.toLowerCase() ||
      suggestedState !== address.state.toLowerCase() ||
      suggestedZip !== address.zip.toLowerCase() ||
      badPlus4
    ) {
      return {
        address1: titleCase(suggestedAddress1),
        city: titleCase(suggestedCity),
        state: suggestedState.toUpperCase(),
        zip: suggestedZip,
      }
    }
    return null
  }
  return null
  // TODO: do I need this globalAddressId to submit a payment?
  // const [, globalAddressId] = addressLookup.results[0].format.split('id=')
  // if (globalAddressId) order.shippingAddress.globalAddressId = globalAddressId
}
