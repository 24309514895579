import React from 'react'
import { oneOfType, string, number } from 'prop-types'

const MaskedCredit = ({ lastFour }) => <>xxxxxxxxxxxx{lastFour}</>

MaskedCredit.propTypes = {
  lastFour: oneOfType([string, number]),
}

export default MaskedCredit
