import styled from 'styled-components'
import { breakPoints, colors, fonts } from '@constants/styles'
import CheckoutInput from '@components/order/status/order-payment/checkout-input'

export const StyledOrderPaymentWrapper = styled.div`
  .order-payment {
    padding: 1rem 0;
  }
  @media print, screen and (max-width: ${breakPoints.small}) {
  }
`

export const StyledOrderPayment = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @media print, screen and (max-width: ${breakPoints.small}) {
  }
`
export const BoldText = styled.b`
  font-weight: 700;
`

export const StyledHorizontalRule = styled.hr`
  margin: 0.5rem 0;
`

export const OrderInformation = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }
  > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  > div > div > span {
    padding-right: 20px;
  }
  @media print, screen and (max-width: ${breakPoints.small}) {
    flex-direction: column;
    margin-right: 0;
    > div > div > span {
      padding-right: 5px;
    }
  }
`

export const StyledButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.white};
  border: 0;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.9rem;
  text-transform: uppercase;
  margin: 0 auto;
  border-radius: 0;
  font-weight: bold;
  margin-top: 0.5rem;
  padding: 0.625rem 1.625rem;
  float: right;
  @media print, screen and (max-width: ${breakPoints.small}) {
    float: left;
  }
`

export const StyledFormButton = styled(StyledButton)`
  height: 40px;
  margin-top: 16px;
  padding: 0;
`

export const TabButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  @media print, screen and (max-width: ${breakPoints.small}) {
    justify-content: center;
  }
`

export const TabButton = styled.button`
  background-color: ${props => props.backgroundColor || colors.primary};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fonts.txtMedium};
  font-weight: 700;
  height: 4rem; // 64px
  margin: 5px 15px 5px 0px;
  min-width: 12rem;
  padding: 20px;
  @media print, screen and (max-width: ${breakPoints.small}) {
    padding: 10px;
    font-size: ${fonts.txtSmall};
    min-width: 0;
  }
`

export const BillingContainer = styled.div``

export const StyledCheckbox = styled.div`
  display: flex;
  margin: 10px 0;
  h4 {
    font-size: ${fonts.txtMediumUp};
  }
  input[type='checkbox'] {
    margin: 0 10px 0 5px;
  }
`

export const StyledBillingAddress = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (min-width: ${breakPoints['large-max']}) {
    width: 75%;
  }
  @media only screen and (min-width: ${breakPoints['x-large']}) {
    width: 50%;
  }
  > div {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }
  > div > * {
    margin-right: 20px;
    width: 100%;
  }
`

export const StyledCheckoutInput = styled(CheckoutInput)`
  border: 1px solid ${colors.lightGrey};
  border-radius: 0px;
  line-height: 20px;
  margin: 0 2.5% 2.5% 0;
  padding: 10px;
  width: 100%;
`

export const StyledSelectInput = styled.select`
  border: 1px solid ${colors.lightGrey};
  border-radius: 0px;
  line-height: 20px;
  margin: 0 2.5% 2.5% 0;
  padding: 10px;
  width: 100%;
`
