import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './checkout-input.sass'

const Input = ({
  className,
  type,
  field,
  label,
  info,
  setInfo,
  afterComponent,
  name,
  radioValue,
  invalidFields,
  textType,
  radioType,
  placeholder,
  required,
}) => {
  const classes = classNames(className, {
    invalid: invalidFields && (invalidFields.includes(field) || invalidFields.includes(className)),
  })
  return (
    <>
      <input
        type={type}
        id={field}
        className={classes || className}
        placeholder={textType ? placeholder : ''}
        aria-required={required}
        aria-invalid={!!(required && classes)}
        value={getValue(textType, radioType, radioValue, info, field)}
        checked={getChecked(textType, radioType, radioValue, info, field)}
        name={radioType ? name : label}
        onChange={e => (!radioType ? setInfo(textType ? e.target.value : e.target.checked, field) : null)}
        onClick={() => (radioType ? setInfo(radioValue, field) : null)}
      />
      <label htmlFor={field}>{afterComponent && afterComponent}</label>
    </>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  info: PropTypes.array,
  setInfo: PropTypes.func,
  afterComponent: PropTypes.any,
  name: PropTypes.string,
  radioValue: PropTypes.any,
  invalidFields: PropTypes.string,
  textType: PropTypes.any,
  radioType: PropTypes.any,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}

const CheckoutInput = props => {
  const { type, field, label, additional, required, placeholder } = props
  const labelText = required ? `${label}*` : label

  const textType = type === 'text' || type === 'email' || type === 'tel'
  const radioType = type === 'radio'
  return (
    <>
      {textType && (
        <label className={classNames('label', field)} htmlFor={field}>
          {labelText} {additional ? <span className="hide508">{additional}</span> : null}
          <Input {...props} textType={textType} radioType={radioType} labelText={labelText} placeholder={placeholder} />
        </label>
      )}
      {!textType && (
        <Input {...props} textType={textType} radioType={radioType} labelText={labelText} placeholder={placeholder} />
      )}
    </>
  )
}

const getChecked = (textType, radioType, radioValue, info, field) => {
  if (textType) {
    return false
  }
  if (radioType) {
    if (info.financePlan && info.financePlan.code ? info.financePlan.code === radioValue.code : radioValue) {
      return info[field]
    }
    return !info[field]
  }
  return info[field]
}

const getValue = (textType, radioType, radioValue, info, field) => {
  if (textType) {
    return info[field]
  }
  if (radioType) {
    return radioValue
  }
  return ''
}

CheckoutInput.propTypes = {
  type: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  additional: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}

export default CheckoutInput
