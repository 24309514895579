import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { states } from '@helpers/geo-location'
import { generateErrorMessage } from '@helpers/errors'
import ErrorMessage from '@shared/error-message'
import { checkManualAddress, getAddressSuggestion } from '@helpers/input-validation'
import { titleCase } from '@helpers/string-helper'
import { shape, string } from 'prop-types'
import AddressSuggestionModal from '@shared/modals/address-suggestion-modal'
import { setOrder } from '@redux/modules/orderStatus'
import {
  BillingContainer,
  StyledBillingAddress,
  StyledButton,
  StyledCheckbox,
  StyledCheckoutInput,
  StyledFormButton,
  StyledSelectInput,
} from '../styles'

const BillingAddress = ({ address }) => {
  const order = useSelector(state => state.orderStatus.order)
  const [invalidFields, setInvalidFields] = useState([])
  const [hasSameAddressAsShipping, setHasSameAddressAsShipping] = useState(true)
  const [billingSubmitted, setBillingSubmitted] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [stateLocation, setStateLocation] = useState('')
  const [zip, setZip] = useState('')
  const [suggestedAddress, setSuggestedAddress] = useState(null)
  const dispatch = useDispatch()

  const resetAddr = () => {
    setFirstName('')
    setLastName('')
    setAddress1('')
    setAddress2('')
    setCity('')
    setStateLocation('')
    setZip('')
    setSuggestedAddress('')
    setBillingSubmitted(false)
  }

  const updateBilling = addr => {
    if (typeof addr === 'object') {
      order.BillingAddress = {
        Address1: addr.address1,
        Address2: addr.address2,
        City: addr.city,
        State: addr.state.toUpperCase(),
        Zip: addr.zip,
      }
    } else {
      order.BillingAddress = {
        Address1: addr ? '' : address1,
        Address2: addr ? '' : address2,
        City: addr ? '' : city,
        State: addr ? '' : stateLocation,
        Zip: addr ? '' : zip,
      }
    }
    dispatch(setOrder(order))
    window.sessionStorage.setItem('orderAddress', JSON.stringify(order.BillingAddress))
  }

  const handleSubmit = async () => {
    // address field validation and suggestions
    const addressState = {
      // firstName,
      // lastName,
      address1,
      address2,
      city,
      state: stateLocation,
      zip,
    }
    let invalidAddressFields = []
    let addressSuggestion = null

    // field validation
    invalidAddressFields = checkManualAddress(
      addressState,
      true, // isBillingAddress
      true, // returnSuggestions
    )
    // set invalid fields in state
    setInvalidFields(invalidAddressFields)

    // if there are no validation issues up to this point, check for suggested address
    if (invalidAddressFields.length === 0) {
      addressSuggestion = await getAddressSuggestion({
        addressState,
        badPlus4: addressState.zip.includes('-') || false,
      })
      setSuggestedAddress(addressSuggestion)
      if (!addressSuggestion) {
        setBillingSubmitted(true)
      }
      updateBilling()
    }
  }
  const suggestModal = !!suggestedAddress
  let suggestedAddressString = null
  if (suggestedAddress) {
    suggestedAddressString = `${suggestedAddress.address1}${suggestedAddress?.address2 || ''}, ${
      suggestedAddress.city
    } ${suggestedAddress.state} ${suggestedAddress.zip}`
  }
  return (
    <BillingContainer>
      <StyledCheckbox>
        <input
          aria-label="Edit Billing Address"
          checked={hasSameAddressAsShipping}
          label="Edit Billing Address"
          name="Edit Billing Address"
          onChange={() => {
            if (!hasSameAddressAsShipping) {
              updateBilling(true) // clear
            } else {
              updateBilling()
            }
            setHasSameAddressAsShipping(!hasSameAddressAsShipping)
          }}
          type="checkbox"
        />
        <span>Billing address same as shipping</span>
      </StyledCheckbox>
      {!hasSameAddressAsShipping && !billingSubmitted && (
        <StyledBillingAddress>
          {invalidFields &&
            invalidFields.map(invalidField => (
              <ErrorMessage
                key={invalidField}
                invalidFields={[invalidField]}
                customMessage={generateErrorMessage(invalidField)}
              />
            ))}
          {suggestModal && invalidFields.length === 0 && (
            <AddressSuggestionModal
              isModalOpen={suggestModal}
              address={{
                firstName,
                lastName,
                address1,
                address2,
                city,
                state: stateLocation,
                zip,
              }}
              suggestedAddress={suggestedAddressString}
              onModalClose={() => {
                setSuggestedAddress('')
              }}
              onAccept={() => {
                setSuggestedAddress('')
                setAddress1(titleCase(suggestedAddress.address1))
                setAddress2('')
                setCity(titleCase(suggestedAddress.city))
                setStateLocation(suggestedAddress.state.toUpperCase())
                setZip(suggestedAddress.zip)
                setBillingSubmitted(true)
                updateBilling(suggestedAddress)
              }}
              onDecline={() => {
                setSuggestedAddress('')
                setBillingSubmitted(true)
              }}
            />
          )}
          {/* <div>
            <StyledCheckoutInput
              field="firstName"
              info={address.FirstName}
              invalidFields={invalidFields}
              label="First Name"
              required
              setInfo={setFirstName}
              type="text"
            />
            <StyledCheckoutInput
              field="lastName"
              info={address.LastName}
              invalidFields={invalidFields}
              label="Last Name"
              required
              setInfo={setLastName}
              type="text"
            />
          </div> */}
          <div>
            <StyledCheckoutInput
              type="text"
              field="address1"
              label="Street Address"
              info={address.LastName}
              setInfo={setAddress1}
              invalidFields={invalidFields}
              required
            />
            <StyledCheckoutInput
              type="text"
              field="address2"
              label="Apt, Suite, Etc"
              info={address.LastName}
              setInfo={setAddress2}
            />
          </div>
          <div>
            <StyledCheckoutInput
              type="text"
              field="city"
              label="City"
              info={address.LastName}
              setInfo={setCity}
              invalidFields={invalidFields}
              required
            />
            <label className="label" htmlFor="state">
              <p>State*</p>
              <StyledSelectInput
                name="state"
                aria-label="State*"
                className={classNames('state', {
                  invalid: invalidFields.includes('state'),
                })}
                value={stateLocation.toUpperCase()}
                onChange={event => {
                  setStateLocation(event.target.value)
                }}
              >
                {states.map(state => (
                  <option key={state[1]} value={state[1]}>
                    {state[1]}
                  </option>
                ))}
              </StyledSelectInput>
            </label>
          </div>
          <div>
            <StyledCheckoutInput
              type="text"
              field="zip"
              label="Zip"
              info={address.Zip}
              setInfo={setZip}
              invalidFields={invalidFields}
              required
            />
            <StyledFormButton
              type="button"
              tabIndex="0"
              value="Submit"
              aria-label="Submit"
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
            </StyledFormButton>
          </div>
        </StyledBillingAddress>
      )}
      {!hasSameAddressAsShipping && billingSubmitted && invalidFields.length === 0 && (
        <div className="billing">
          <h4>Billing Information</h4>
          <div style={{ display: 'table', margin: '0 0 .5em 0' }}>
            <StyledButton
              type="button"
              tabIndex="0"
              value="Edit Billing Address"
              aria-label="Edit Billing Address"
              onClick={() => {
                resetAddr()
                updateBilling(true)
              }}
            >
              Edit Billing Address
            </StyledButton>
          </div>
          <div className="left-info">
            {`${firstName} ${lastName}`}
            <br />
            {address1}
            {address2 !== '' && ` ${address2}`}
            <br />
            {`${city} `}
            {`${stateLocation} `}
            {zip}
          </div>
        </div>
      )}
    </BillingContainer>
  )
}

BillingAddress.propTypes = {
  address: shape({
    FirstName: string,
    LastName: string,
    Address1: string,
    Address2: string,
    City: string,
    State: string,
    Zip: string,
  }).isRequired,
}

export default BillingAddress
